import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Auth } from "../types/auth.type";
import { withStorageSync } from "@angular-architects/ngrx-toolkit";
import { patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { Router } from "@angular/router";
import { Courses } from "./courses.store";

const initialState: Auth = {
  isLoading: false,
  user: null,
  role: null,
  admin: false,
};

export const AuthState = signalStore(
  { providedIn: "root" },
  withStorageSync({
    key: "MLuser",
    storage: () => localStorage,
  }),
  withState(initialState),
  withMethods(
    (
      store,
      authService = inject(AuthService),
      router = inject(Router),
      coursesState = inject(Courses)
    ) => ({
      async signInWithGoogle() {
        patchState(store, { isLoading: true });
        try {
          await authService.signInWithGoogle();
          await this._handleSuccessfulSignIn();
          if (store.role() === "teacher") {
            router.navigate(["/larare"]);
          } else {
            router.navigate(["/"]); //Change later depending on role
          }
        } catch (error) {
          this._handleError(error);
        } finally {
          patchState(store, { isLoading: false });
        }
      },

      async _handleSuccessfulSignIn() {
        const idTokenResult = await authService.getUserClaims();
        const role = (idTokenResult?.claims as { [key: string]: string })[
          "role"
        ];
        const admin = (idTokenResult?.claims as { [key: string]: boolean })[
          "admin"
        ];

        patchState(store, {
          user: authService.auth.currentUser,
          role,
          admin,
        });
      },
      _handleError(error: unknown) {
        console.error(error); //Build a custom error handling service
      },

      async signOut() {
        patchState(store, { isLoading: true });
        await authService.auth.signOut();

        store.clearStorage();
        coursesState.clearStorage();
        patchState(store, {
          user: null,
          role: null,
          isLoading: false,
        });
        router.navigate(["/"]);
      },

      //Only for debugging - remove on production deployment
      async getUserToken() {
        patchState(store, { isLoading: true });
        const token = await authService.getUserToken();
        patchState(store, { isLoading: false });
        return token;
      },
    })
  )
);
